import React, { useState, useEffect } from "react";
import "./App.css";

const BubbleApp = () => {
  const [messages, setMessages] = useState([]);
  const [displayText, setDisplayText] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#e0e0e0"); // Initial background color
  const [showColorList, setShowColorList] = useState(false); // State to show/hide color list
  const [time, setTime] = useState(0); // State for the timer in seconds
  const [isRunning, setIsRunning] = useState(false); // State to check if timer is running
  const [recordedTimes, setRecordedTimes] = useState([]); // State to store recorded times with actions
  const [waveEffect, setWaveEffect] = useState(false); // State to toggle wave light effect
  const [youtubeLink, setYoutubeLink] =
    useState();
  // "https://youtu.be/lJKfJIR3rh0" // State for the YouTube video link
  const [showVideoPlayer, setShowVideoPlayer] = useState(false); // State to show/hide video player
  const [autoDismissBubbles, setAutoDismissBubbles] = useState(false); // State to toggle auto-dismiss of bubbles
  const [totalMessages, setTotalMessages] = useState(0); // State to count total messages
  const [showColorPicker, setShowColorPicker] = useState(false);

  const colors = [
    "rgb(212 189 148)",
    "rgb(148 212 189)",
    "rgb(148 196 212)",
    "rgb(212 148 148)",
    "#e0e0e0",
    "#26882b",
    "#eb8406",
    "#00ff40",
    "#688b68",
    "#FF00FF",
    "#ffffff",
    "#f28b82",
    "#fbbc04",
    "#fff475",
    "#ccff90",
    "#a7ffeb",
    "#cbf0f8",
    "#aecbfa",
    "#d7aefb",
    "#fdcfe8",
  ];

  const changeBackgroundColor = (color) => {
    setBackgroundColor(color);
    setShowColorList(false);
  };

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  useEffect(() => {
    document.title = `Time | Focus: ${time}s`; // Update document title with current timer value
    return () => {
      document.title = ""; // Reset document title on component unmount
    };
  }, [time]);

  // const handleStart = () => {
  //   setIsRunning(true);
  //   setRecordedTimes([...recordedTimes, { action: "Start", time }]);
  // };

  const handleStart = () => {
    setIsRunning(true);
  };
  // const handlePauseResume = () => {
  //   if (isRunning) {
  //     setIsRunning(false);
  //     setRecordedTimes([...recordedTimes, { action: "Break", time }]);
  //   } else {
  //     setIsRunning(true);
  //     setRecordedTimes([...recordedTimes, { action: "Resume", time }]);
  //   }
  // };
  const handlePauseResume = () => {
    if (isRunning) {
      setIsRunning(false);
      setRecordedTimes([...recordedTimes, time]);
    } else {
      setIsRunning(true);
    }
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
    setRecordedTimes([]);
  };

  const calculateTotalBreakTime = () => {
    let totalBreakTime = 0;
    let lastBreakStart = null;

    recordedTimes.forEach((record) => {
      if (record.action === "Break") {
        lastBreakStart = record.time;
      } else if (record.action === "Resume" && lastBreakStart !== null) {
        totalBreakTime += record.time - lastBreakStart;
        lastBreakStart = null;
      }
    });

    return totalBreakTime;
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    return `${h}:${m}`;
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      const typedChar = e.key;

      if (e.key === "Enter") {
        if (displayText.trim() !== "") {
          const newMessage = {
            id: new Date().getTime(),
            text: displayText.trim(),
          };
          if (messages.length >= 7) {
            const updatedMessages = messages.slice(1); // Remove oldest message if there are already 7
            setMessages([...updatedMessages, newMessage]);
          } else {
            setMessages((prevMessages) => [...prevMessages, newMessage]);
          }
          setDisplayText("");
        }
      } else if (e.key === "Backspace") {
        setDisplayText((prevText) => prevText.slice(0, -1));
      } else if (e.key === "Shift") {
      } else if (
        /^[a-zA-Z0-9\s,.?!@]+$/.test(typedChar) ||
        (e.shiftKey && typedChar.length === 1)
      ) {
        setDisplayText((prevText) => {
          const newText =
            prevText + (e.shiftKey ? typedChar.toUpperCase() : typedChar);
          return newText.length > 85 ? newText.slice(0, 85) : newText;
        });
      }
    };

    const timeoutIds = autoDismissBubbles
      ? messages.map((message, index) => {
        return setTimeout(() => {
          const updatedMessages = [...messages];
          updatedMessages.splice(index, 1);
          setMessages(updatedMessages);
        }, 2000);
      })
      : [];

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [displayText, messages, autoDismissBubbles]);

  useEffect(() => {
    setTotalMessages(messages.length);
  }, [messages]);

  const handleBubbleClick = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  const handleVideoLinkChange = (e) => {
    setYoutubeLink(e.target.value);
  };

  const handleVideoSubmit = () => {
    const videoId = getYoutubeVideoId(youtubeLink);
    if (videoId) {
      setShowVideoPlayer(true);
    }
  };

  const toggleBubbleBehavior = () => {
    setAutoDismissBubbles(!autoDismissBubbles);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "Did you have a good day? and Complete all tasks?";
      console.log("Did you have a good day? and Complete all tasks?");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleColorPickerClick = () => {
    setShowColorPicker(true);
  };

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
    setShowColorPicker(false); // Hide the color picker after a color is selected
  };

  return (
    <div
      className="app-container"
      style={{ backgroundColor }}
      onClick={() => setDisplayText("")}
    >
      <div className="title-logo">
        <div className="title">
          <h1>One Day Focused</h1>
          <span className="cursor"></span>
        </div>
        <br /> (Typing on Large Screen)
        <ul>
          <li>Track Working Habit</li>
          <li>Take One Day Notes</li>
          <li>Change Your Day Color</li>
          <li>Play Your Day Sound</li>
        </ul>
      </div>

      <div className="header">
        <div className="timer">
          {/* <h2>Focusing</h2> */}
          {/* <br /> */}
          {/* <div className="status-box"> */}
          <img src="./icon.png"></img>

          <h3
            style={{
              minWidth: "fit-content",
              textAlign: "end",
              margin: "1px",
              borderBlockColor: "#333",
            }}
          >
            Status:
          </h3>
          <h3
            style={{
              minWidth: "fit-content",
              textAlign: "end",
              margin: "1px",
              borderBlockColor: "#333",
            }}
          >
            <span>
              {isRunning ? (
                <a style={{ color: "orange", backgroundColor: "#333" }}>
                  Focusing
                </a>
              ) : (
                <a style={{ color: "#f41818", backgroundColor: "#333" }}>
                  Breaking
                </a>
              )}
            </span>
          </h3>

          {/* </div> */}
          <div className="counter">{formatTime(time)}</div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(time % 60) * 1.6667}%` }}
            ></div>
          </div>
          <div className="buttons-bar">
            <button
              style={{ backgroundColor }}
              onClick={handleStart}
              disabled={isRunning}
            >
              Start
            </button>
            <button style={{ backgroundColor }} onClick={handlePauseResume}>
              {isRunning ? "Break" : "Resume"}
            </button>
            <button style={{ backgroundColor }} onClick={handleReset}>
              Reset
            </button>
          </div>

          <div className="recorded-times-container">
            <div className="recorded-times">
              {recordedTimes.map((recordedTime, index) => (
                <div key={index}>Recorded: {formatTime(recordedTime)}</div>
              ))}
            </div>
          </div>
          <button style={{ backgroundColor }} onClick={toggleBubbleBehavior}>
            {autoDismissBubbles ? "Enable Taking Note" : "Disable Taking Note"}
          </button>
          <p>Total Notes: {totalMessages}</p>
        </div>
      </div>

      <div className="right-panel">
        <button
          style={{ backgroundColor }}
          onClick={() => setShowColorList(!showColorList)}
        >
          What is your color day?
        </button>
        {showColorList && (
          <div className="color-list">
            {colors.map((color) => (
              <button
                key={color}
                style={{
                  backgroundColor: color,
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                  border: '1px solid #333',
                }}
                onClick={() => changeBackgroundColor(color)}
              />
            ))}
          </div>
        )}
        <div className="youtube-container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter Your Sound"
              value={youtubeLink}
              onChange={handleVideoLinkChange}
            />
          </div>
          <button style={{ backgroundColor }} onClick={handleVideoSubmit}>
            Play Video
          </button>
          <button
            style={{ backgroundColor }}
            onClick={() => setShowVideoPlayer(false)}
          >
            Off Video
          </button>
          {showVideoPlayer && youtubeLink && (
            <div className="video-player">
              <iframe
                width="250"
                height="135"
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                  youtubeLink
                )}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
        <footer>
          <div className="">
            <span>
              Copyright ©{" "}
              <span id="get-current-year">
                2024 -{" "}
                <span>
                  <a href="https://longdatadevlog.com">Long Bui</a>
                </span>
                . All rights reserved.
              </span>
            </span>
          </div>
        </footer>
      </div>
      <div className="bubble">
        {messages.map((message) => (
          <div
            key={message.id}
            className="chat-bubble"
            onClick={() => !autoDismissBubbles && handleBubbleClick(message.id)}
            title="Click to mark it done"
          >
            {message.text}
          </div>
        ))}
        {displayText.trim() !== "" && (
          <div className="current-bubble">{displayText}</div>
        )}
      </div>
      {waveEffect && <div className="wave"></div>}
    </div>
  );
};

const getYoutubeVideoId = (url) => {
  const regExp = /^.*(?:youtu\.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : null;
};

export default BubbleApp;
